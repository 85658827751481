/*
Template Name: UBold Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Pages
*/
/* =============
   Calendar
============= */
.calendar {
  float: left;
  margin-bottom: 0px;
}
.fc-view {
  margin-top: 30px;
}
.none-border .modal-footer {
  border-top: none;
}
.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px;
}
.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
}
.fc-day {
  background: #ffffff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}
.fc-widget-header {
  border: 1px solid #ebeff2;
}
.fc-widget-content {
  border: 1px solid #ebeff2;
}
.fc th.fc-widget-header {
  background: #ebeff2;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0px;
  text-transform: uppercase;
}
.fc-button {
  background: #ffffff;
  border: 1px solid #ebeff2;
  color: #555555;
  text-transform: capitalize;
}
.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}
.fc-state-hover {
  background: #F5F5F5;
}
.fc-state-highlight {
  background: #f0f0f0;
}
.fc-cell-overlay {
  background: #f0f0f0;
}
.fc-unthemed .fc-today {
  background: #ffffff;
}
.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}
.external-event {
  color: #ffffff;
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}
.fc-basic-view td.fc-week-number span {
  padding-right: 5px;
}
.fc-basic-view td.fc-day-number {
  padding-right: 5px;
}
/* =============
   Form Advanced
============= */
.bootstrap-tagsinput {
  box-shadow: none;
  padding: 3px 7px 6px;
  border: 1px solid #e3e3e3;
}
.bootstrap-tagsinput .label-info {
  background-color: #5fbeaa !important;
  display: inline-block;
  padding: 5px;
}
/* Multiple */
.ms-container {
  background: transparent url('../images/multiple-arrow.png') no-repeat 50% 50%;
}
.ms-container .ms-list {
  box-shadow: none;
  border: 1px solid #e3e3e3;
}
.ms-container .ms-list.ms-focus {
  box-shadow: none;
  border: 1px solid #aaaaaa;
}
.ms-container .ms-selectable li.ms-elem-selectable {
  border: none;
  padding: 5px 10px;
}
.ms-container .ms-selection li.ms-elem-selection {
  border: none;
  padding: 5px 10px;
}
.select2-container .select2-choice {
  background-image: none !important;
  border: none !important;
  height: auto  !important;
  padding: 0px !important;
  line-height: 22px !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.select2-container .select2-choice .select2-arrow {
  background-image: none !important;
  background: transparent;
  border: none;
  width: 14px;
  top: -2px;
}
.select2-container .select2-container-multi.form-control {
  height: auto;
}
.select2-results .select2-highlighted {
  color: #ffffff;
  background-color: #5fbeaa;
}
.select2-drop-active {
  border: 1px solid #e3e3e3 !important;
  padding-top: 5px;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}
.select2-search input {
  border: 1px solid #e3e3e3;
}
.select2-container-multi {
  width: 100%;
}
.select2-container-multi .select2-choices {
  border: 1px solid #E3E3E3  !important;
  box-shadow: none !important;
  background-image: none  !important;
  -webkit-border-radius: 4px !important;
  border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  background-clip: padding-box !important;
  min-height: 38px;
}
.select2-container-multi .select2-choices .select2-search-choice {
  padding: 4px 7px 4px 18px;
  margin: 5px 0 3px 5px;
  color: #555555;
  background: #f5f5f5;
  border-color: #e5e5e5;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-container-multi .select2-choices .select2-search-field input {
  padding: 7px 7px 7px 10px;
  font-family: inherit;
}
/* Bootstrap-select */
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100% !important;
}
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}
.bootstrap-select .glyphicon {
  padding-right: 6px;
}
/* Bootstrap filestyle */
.icon-span-filestyle {
  padding-right: 5px;
}
/* Bootstrap-touchSpin */
.bootstrap-touchspin .input-group-btn-vertical .btn {
  padding: 9px 12px;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  top: 4px;
  left: 8px;
}
/* Wysiwig */
.mce-content-body p {
  color: #9398a0;
  font-size: 14px;
  font-weight: 300;
}
.mce-popover .mce-arrow:after {
  border-bottom-color: red;
}
.mce-popover .mce-colorbutton-grid {
  margin: 0px;
  border: 1px solid #d7dce5 !important;
  padding: 4px;
}
.mce-reset .mce-window-head {
  border-bottom: 1px solid #d7dce5;
}
.mce-reset .mce-window-head .mce-title {
  color: #707780;
  font-size: 16px;
  font-weight: 400;
}
.mce-reset .mce-textbox {
  border-radius: 0px;
  box-shadow: none;
  outline: 0;
  border-color: #d7dce5;
  height: 30px;
  font-weight: 300;
  line-height: 30px;
  color: #aaaaaa;
  font-size: 14px;
}
.mce-reset .mce-textbox:focus {
  box-shadow: none;
  border-color: #5fbeaa;
}
.mce-reset .mce-checkbox .mce-ico {
  background-image: none;
  background-color: #ffffff;
  border-radius: 0px;
  border: 1px solid #d7dce5;
}
.mce-reset .mce-checkbox .mce-label {
  color: #707780;
  font-size: 12px;
  font-weight: 400;
}
.mce-container {
  border-radius: 0px !important;
  border-width: 0px !important;
}
.mce-container .mce-menubar {
  background-color: #f2f4f7 !important;
  border: 1px solid #d7dce5 !important;
  padding: 2px;
}
.mce-container .mce-menubar .mce-btn button span {
  color: #707780;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.mce-container .mce-menubar .mce-btn button .mce-caret {
  border-top-color: #707780;
}
.mce-container .mce-menubar .mce-btn button:hover {
  background-color: #e8ebf1;
}
.mce-container .mce-menubar .mce-btn.mce-active button {
  background-color: #e8ebf1;
}
.mce-container .mce-btn {
  background-color: #d7dce5;
  background-image: none;
  outline: 0;
  border: 0px;
  border-radius: 0px;
}
.mce-container .mce-btn button {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-shadow: none;
}
.mce-container .mce-btn:hover {
  background-color: #b8c1d1;
  background-image: none;
}
.mce-container .mce-primary {
  background-color: #5fbeaa;
  background-image: none;
  outline: 0;
  border: 0px;
  border-radius: 0px;
}
.mce-container .mce-primary button {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-shadow: none;
}
.mce-container .mce-primary:hover {
  background-color: #0c7cd5;
  background-image: none;
}
.mce-container .mce-toolbar-grp {
  background-color: #f2f4f7 !important;
  border: 1px solid #d7dce5 !important;
  border-top-width: 0px !important;
  padding: 6px;
}
.mce-container .mce-edit-area {
  border: 1px solid #d7dce5 !important;
  border-width: 0px 1px !important;
}
.mce-container .mce-statusbar {
  background-color: #f2f4f7 !important;
  border: 1px solid #d7dce5 !important;
}
.mce-container .mce-statusbar .mce-path .mce-path-item {
  color: #707780;
  font-size: 14px;
  font-weight: 400;
}
.mce-container .mce-widget {
  color: #9398a0;
  font-size: 14px;
  font-weight: 400;
  border-left: 1px solid transparent;
}
.mce-container .mce-btn-group {
  border: 1px solid #e9ecf2 !important;
}
.mce-container .mce-btn-group .mce-btn {
  box-shadow: none;
  background-image: none;
  background-color: #ffffff;
  border-width: 0px;
  border-radius: 0px !important;
}
.mce-container .mce-btn-group .mce-btn:hover,
.mce-container .mce-btn-group .mce-btn:focus {
  box-shadow: none;
  background-image: none;
  background-color: #ffffff;
}
.mce-container .mce-btn-group .mce-btn button span {
  color: #707780;
  font-size: 14px;
  font-weight: 300;
}
.mce-container .mce-btn-group .mce-btn button .mce-caret {
  color: #707780;
  font-size: 14px;
}
.mce-container .mce-ico {
  color: #707780;
  font-size: 14px;
}
.mce-container .mce-panel {
  background-image: none;
}
.mce-container.mce-menu {
  border: 1px solid #d7dce5 !important;
}
.mce-container.mce-menu .mce-menu-item {
  background-image: none;
}
.mce-container.mce-menu .mce-menu-item .mce-ico {
  color: #5fbeaa;
  font-size: 14px;
}
.mce-container.mce-menu .mce-menu-item .mce-text {
  color: #707780;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.mce-container.mce-menu .mce-menu-item .mce-menu-shortcut {
  color: #aaaaaa;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
}
.mce-container.mce-menu .mce-menu-item:hover,
.mce-container.mce-menu .mce-menu-item:focus,
.mce-container.mce-menu .mce-menu-item.mce-selected {
  background-color: #5fbeaa;
}
.mce-container.mce-menu .mce-menu-item:hover .mce-ico,
.mce-container.mce-menu .mce-menu-item:focus .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-ico,
.mce-container.mce-menu .mce-menu-item:hover .mce-text,
.mce-container.mce-menu .mce-menu-item:focus .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-text,
.mce-container.mce-menu .mce-menu-item:hover .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item:focus .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-menu-shortcut {
  color: #ffffff;
}
.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-menu-shortcut {
  color: #aaaaaa;
}
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected {
  background-color: #d7dce5;
}
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-menu-shortcut {
  color: #ffffff;
}
.mce-container.mce-menu .mce-menu-item-sep {
  background-color: #d7dce5;
}
.mce-container.mce-menu .mce-menu-item-sep:hover {
  background-color: #d7dce5;
}
.mce-menubtn button {
  color: #36404a !important;
}
.mce-menu-item-normal.mce-active {
  background-color: #5fbeaa !important;
}
.mce-menu-item-normal.mce-active .mce-text {
  color: #ffffff !important;
}
/* =============
   Notification
============= */
.notifyjs-metro-base {
  position: relative;
  min-height: 52px;
  min-width: 250px;
  color: #444;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
  -webkit-animation: dropdownOpen 0.3s ease-out;
  -o-animation: dropdownOpen 0.3s ease-out;
  animation: dropdownOpen 0.3s ease-out;
}
.notifyjs-metro-base .image {
  display: table;
  position: absolute;
  height: auto;
  width: auto;
  left: 25px;
  top: 50%;
  font-size: 24px;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notifyjs-metro-base .text-wrapper {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin: 10px 10px 10px 52px;
  clear: both;
}
.notifyjs-metro-base .title {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: bold;
}
.notifyjs-metro-base .text {
  font-size: 12px;
  font-weight: normal;
  max-width: 360px;
  vertical-align: middle;
}
.notifyjs-metro-cool {
  color: #fafafa !important;
  background-color: #4A525F;
  border: 1px solid #4A525F;
}
/* =============
   Bootstrap Range slider
============= */
.slider .slider-horizontal {
  margin: 10px 0;
}
.slider .slider-vertical {
  margin: 0 10px;
}
.slider-handle.round {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #EFF2F7;
  background: #fff;
  cursor: pointer;
}
.slider-default .slider-selection {
  background-image: none;
  background-color: #909aa0;
}
.slider-primary .slider-selection {
  background-image: none;
  background-color: #5d9cec;
}
.slider-success .slider-selection {
  background-image: none;
  background-color: #81c868;
}
.slider-info .slider-selection {
  background-image: none;
  background-color: #34d3eb;
}
.slider-warning .slider-selection {
  background-image: none;
  background-color: #ffbd4a;
}
.slider-danger .slider-selection {
  background-image: none;
  background-color: #f05050;
}
.slider-custom .slider-selection {
  background-image: none;
  background-color: #5fbeaa;
}
.slider-pink .slider-selection {
  background-image: none;
  background-color: #fb6d9d;
}
.slider-purple .slider-selection {
  background-image: none;
  background-color: #7266ba;
}
.slider-inverse .slider-selection {
  background-image: none;
  background-color: #4c5667;
}
/* =============
   Charts
============= */
.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 10px 12px;
  color: #666;
  background: #36404a;
  border: none;
  color: #ffffff !important;
}
.morris-hover.morris-default-style .morris-hover-point {
  color: #f4f8fb !important;
}
.chart-detail-list li {
  margin: 0px 10px;
}
.chart-detail-list li h5 {
  font-size: 15px;
}
.pieLabel div {
  font-size: 14px !important;
}
.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.chart.chart-widget-pie {
  margin-top: 5px;
  margin-bottom: 5px;
}
.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;
  font-weight: 600;
  font-size: 18px;
  color: #36404a;
}
.percent:after {
  content: '%';
  margin-left: 0.1em;
  font-size: .8em;
}
#flotTip {
  padding: 8px 12px;
  background-color: #36404a;
  z-index: 100;
  color: #ffffff;
  opacity: 0.9;
  font-size: 13px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.legend tr {
  height: 20px;
}
.legendLabel {
  padding-left: 5px !important;
  line-height: 10px;
  padding-right: 10px;
}
/* C3 chart */
.c3-tooltip td > span {
  background: #36404a;
}
.c3-tooltip td {
  border-left: none;
}
.c3-tooltip {
  -webkit-box-shadow: 0px 0px 8px 0px #777777;
  box-shadow: 0px 0px 8px 0px #777777;
  -moz-box-shadow: 0px 0px 8px 0px #777777;
  opacity: 1;
}
.c3-chart-arcs-title {
  font-size: 18px;
  font-weight: 600;
}
.c3-tooltip tr {
  border: none !important;
}
.c3-tooltip th {
  background-color: #36404a;
}
/* Chartist chart */
.ct-golden-section:before {
  float: none;
}
.ct-chart {
  height: 300px;
}
.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 12px;
  line-height: 1;
}
.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 16px;
}
.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: #5d9cec;
}
.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #fb6d9d;
}
.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #34d3eb;
}
.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #5fbeaa;
}
.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #36404a;
}
.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #7266ba;
}
.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #81c868;
}
.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #5d9cec;
}
.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #fb6d9d;
}
.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #34d3eb;
}
.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #5fbeaa;
}
/* Circliful charts */
.circliful-chart {
  margin: 0px auto;
}
.circle-text,
.circle-info,
.circle-text-half,
.circle-info-half {
  font-size: 12px;
  font-weight: 600;
}
/* =============
   Count Down
============= */
.home-wrapper {
  margin: 10% 0px;
}
.home-text {
  font-weight: 600;
}
.u-countdown {
  margin-top: 40px;
  text-align: center;
}
.u-countdown div {
  display: inline-block;
}
.u-countdown div span {
  display: block;
  width: 150px;
}
.u-countdown div span:first-child {
  font-size: 3em;
  font-weight: 700;
  height: 48px;
  line-height: 48px;
}
.u-countdown div span:last-child {
  color: #333333;
  font-size: 0.9em;
  height: 25px;
  line-height: 25px;
}
.u-countdown > * {
  text-align: center;
}
.cd-text {
  font-size: 15px;
  line-height: 24px;
  font-style: italic;
}
/* =============
   Timeline
============= */
.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.cd-container::after {
  content: '';
  display: table;
  clear: both;
}
#cd-timeline {
  margin-bottom: 2em;
  margin-top: 2em;
  padding: 2em 0;
  position: relative;
}
#cd-timeline::before {
  background: #ffffff;
  content: '';
  height: 100%;
  left: 18px;
  position: absolute;
  top: 0;
  width: 4px;
}
@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-bottom: 3em;
    margin-top: 3em;
  }
  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}
.cd-timeline-block {
  margin: 2em 0;
  position: relative;
}
.cd-timeline-block:after {
  clear: both;
  content: "";
  display: table;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}
.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #fff;
}
.cd-timeline-img.cd-success {
  background: #81c868;
}
.cd-timeline-img.cd-info {
  background: #34d3eb;
}
.cd-timeline-img.cd-pink {
  background: #fb6d9d;
}
.cd-timeline-img.cd-danger {
  background: #f05050;
}
.cd-timeline-img.cd-primary {
  background: #5d9cec;
}
.cd-timeline-img.cd-warning {
  background: #ffbd4a;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    line-height: 60px;
    left: 50%;
    margin-left: -30px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}
.cd-timeline-content {
  -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 0;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  margin-left: 60px;
  padding: 1em;
  position: relative;
}
.cd-timeline-content img {
  display: block;
  width: 100%;
}
.cd-timeline-content:after {
  clear: both;
  content: "";
  display: table;
}
.cd-timeline-content h2 {
  margin-top: 0;
}
.cd-timeline-content p {
  color: #666666;
  font-size: 14px;
  margin: 10px 0px 10px 0px;
}
.cd-timeline-content .cd-read-more {
  background: #acb7c0;
  border-radius: 0.25em;
  color: white;
  display: inline-block;
  float: right;
  font-size: 14px;
  padding: .8em 1em;
}
.cd-timeline-content .cd-date {
  display: inline-block;
  font-size: 14px;
}
.cd-timeline-content h3 {
  font-size: 21px;
  margin: 0px;
}
.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}
.cd-timeline-content .cd-date {
  float: left;
  padding: .8em 0;
  opacity: .7;
}
.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }
  .cd-timeline-content .cd-read-more {
    float: left;
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
}
@media only screen and (min-width: 1170px) {
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
/* Time line 2 */
.timeline-2 {
  border-left: 2px solid #98a6ad;
  position: relative;
}
.timeline-2 .time-item:after {
  background-color: #ffffff;
  border-color: #98a6ad;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: '';
  height: 14px;
  left: 0;
  margin-left: -8px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.time-item {
  border-color: #dee5e7;
  padding-bottom: 1px;
  position: relative;
}
.time-item:before {
  content: " ";
  display: table;
}
.time-item:after {
  background-color: #ffffff;
  border-color: #98a6ad;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: '';
  height: 14px;
  left: 0;
  margin-left: -8px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.time-item-item:after {
  content: " ";
  display: table;
}
.item-info {
  margin-bottom: 15px;
  margin-left: 15px;
}
.item-info p {
  margin-bottom: 10px !important;
}
/* =============
   Email
============= */
.mails a {
  color: #797979;
}
.mails td {
  vertical-align: middle !important;
  position: relative;
}
.mails td:last-of-type {
  width: 100px;
  padding-right: 20px;
}
.mails tr:hover .text-white {
  display: none;
}
.mails .mail-select {
  padding: 12px 20px;
  min-width: 134px;
}
.mails .checkbox {
  margin-bottom: 0px;
  margin-top: 0px;
  vertical-align: middle;
  display: inline-block;
  height: 17px;
}
.mails .checkbox label {
  min-height: 16px;
}
.mail-list .list-group-item {
  background-color: transparent;
}
.mail-list .list-group-item:hover {
  background-color: #f4f8fb;
}
.mail-list .list-group-item:focus {
  background-color: #f4f8fb;
}
.mail-list .list-group-item.active {
  background-color: #5fbeaa;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
}
.unread a {
  font-weight: 600;
  color: #444444;
}
/* =============
   Gallery
============= */
.portfolioFilter a {
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-out;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  color: #333333;
  padding: 5px 10px;
  display: inline-block;
  margin-bottom: 5px;
}
.portfolioFilter a:hover {
  background-color: #5d9cec;
  color: #ffffff;
}
.portfolioFilter a.current {
  background-color: #5d9cec;
  color: #ffffff;
}
.thumb {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  width: 100%;
}
.thumb-img {
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
}
.gal-detail h4 {
  margin-top: 16px;
}
/* =============
   Maintenance
============= */
.icon-main {
  font-size: 88px;
  margin-bottom: 50px;
}
.maintenance-page {
  margin: 10% 0%;
}
/* =============
   Maps
============= */
.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #eeeeee;
  border-radius: 3px;
}
.gmaps-overlay {
  display: block;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  background: #5d9cec;
  border-radius: 4px;
  padding: 10px 20px;
}
.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}
.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #5d9cec;
}
.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #5d9cec;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.jvectormap-zoomout {
  top: 40px;
}
/* =============
   Nestable
============= */
.custom-dd .dd-list .dd-item .dd-handle {
  background: #f4f8fb;
  border: none;
  padding: 8px 16px;
  height: auto;
  font-weight: 600;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
}
.custom-dd .dd-list .dd-item .dd-handle:hover {
  color: #5fbeaa;
}
.custom-dd .dd-list .dd-item button {
  height: auto;
  font-size: 17px;
  margin: 8px auto;
  color: #555555;
  width: 30px;
}
.custom-dd-empty .dd-list .dd3-handle {
  border: none;
  background: #f4f8fb;
  height: 36px;
  width: 36px;
}
.custom-dd-empty .dd-list .dd3-handle:before {
  color: inherit;
  top: 7px;
}
.custom-dd-empty .dd-list .dd3-handle:hover {
  color: #5fbeaa;
}
.custom-dd-empty .dd-list .dd3-content {
  height: auto;
  border: none;
  padding: 8px 16px 8px 46px;
  background: #f4f8fb;
  font-weight: 600;
}
.custom-dd-empty .dd-list .dd3-content:hover {
  color: #5fbeaa;
}
.custom-dd-empty .dd-list button {
  width: 26px;
  height: 26px;
  font-size: 16px;
  font-weight: 600;
}
/* =============
   Pricing
============= */
.pricing-plan {
  padding-bottom: 50px;
}
.price_card {
  -moz-border-radius: 5px;
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  background: #fafafa;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding-bottom: 20px;
  position: relative;
  webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.price_card .name {
  display: block;
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 30px;
  text-align: center;
  width: 100%;
}
.price_card button {
  margin-top: 20px;
}
.pricing-header {
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #ffffff;
}
.price {
  display: block;
  font-size: 48px;
  font-weight: 300;
  padding: 30px 0 10px;
  text-align: center;
  width: 100%;
}
.price-features {
  color: #8a8a8a;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.price-features li {
  margin: 0 35px;
  padding: 20px 15px;
}
.pricing-item {
  margin: 0 0 30px;
  position: relative;
  text-align: center;
}
.pricing-item-inner {
  -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -ms-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  border-radius: 7px;
  border: 2px solid rgba(151, 160, 175, 0.2);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  vertical-align: middle;
}
.pricing-wrap {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  padding: 30px 20px;
  position: relative;
  text-align: center;
}
.pricing-icon {
  font-size: 32px;
  padding-bottom: 5px;
  padding-top: 10px;
  position: relative;
  z-index: 1;
}
.pricing-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 40px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
.pr-list {
  color: #97a0af;
  font-size: 12px;
  font-weight: 400;
  margin: 0 -20px 30px;
  padding: 0;
  text-transform: uppercase;
}
.pr-list li {
  list-style: none;
  padding: 12px 20px;
}
.pricing-num {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
}
.pricing-num sup {
  font-size: 18px;
  font-weight: 400;
  left: -3px;
  margin-left: -7px;
  position: relative;
  top: -20px;
}
.pr-per {
  color: #97a0af;
  font-size: 12px;
}
.pr-button {
  margin-top: 30px;
}
/* =============
   Profile
============= */
.bg-picture {
  margin: -20px -25px 0px -25px;
  padding: 100px 0px;
  position: relative;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.profile-info-name {
  position: relative;
}
.profile-tablist li a {
  display: block;
  color: #36404a;
  padding: 10px 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.profile-tablist li.active {
  background-color: #5fbeaa;
}
.profile-tablist li.active a {
  color: #ffffff;
}
.profile-tab-content {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-top: 40px;
}
.user-tabs {
  background-color: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-left: -35px;
  padding-left: 25px;
}
.user-tabs .nav.nav-tabs {
  box-shadow: none !important;
}
.user-tabs .nav.nav-tabs a {
  text-transform: uppercase;
}
.user-tabs .pull-right .btn {
  margin-top: 8px;
}
.about-info-p {
  margin-bottom: 20px;
}
.about-info-p p {
  font-size: 16px;
}
/* =============
   Extra pages
============= */
/*  sitemap-wrapper */
.sitemap-wrapper a {
  color: #555555;
}
.sitemap-wrapper a:hover {
  color: #5fbeaa;
}
.sitemap-wrapper h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}
.sitemap-wrapper h5 i {
  padding-right: 10px;
}
.sitemap-wrapper ul {
  padding-left: 30px;
}
.sitemap-wrapper ul li a {
  font-size: 15px;
  line-height: 30px;
}
/*======= FAQ ======*/
.faq-box {
  padding: 24px 0px;
  border-bottom: 1px solid #ebeff2;
}
.faq-box .question {
  font-weight: 600;
  font-size: 16px;
  margin-top: 0px;
}
.faq-box .answer {
  color: #98a6ad;
  margin-bottom: 0px;
}
/* Search result */
.search-result-box .tab-content {
  padding: 30px 30px 10px 30px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: none;
}
.search-result-box .search-item {
  padding-bottom: 20px;
  border-bottom: 1px solid #ebeff2;
  margin-bottom: 30px;
}
/*  Contact */
.contact-search .btn-white {
  position: absolute;
  top: 1px;
  right: 16px;
  background-color: transparent !important;
  border: none !important;
  font-size: 16px;
  box-shadow: none !important;
  outline: none !important;
  color: #98a6ad;
}
.contact-card {
  position: relative;
}
.contact-card:hover .contact-action {
  display: block;
}
.contact-card img {
  width: 80px;
  height: 80px;
}
.contact-card .member-info {
  padding-left: 100px;
  padding-bottom: 20px;
}
.contact-card .member-info h4,
.contact-card .member-info p {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}
.contact-card .contact-action {
  position: absolute;
  right: 0px;
  top: 0px;
  display: none;
}
/*  Image crop */
#showDataURL img {
  width: 100%;
}
/* Switchery demo */
.switchery-demo .switchery {
  margin-bottom: 10px;
}
/* Chat app */
.chat-app-list {
  padding: 0px 20px;
}
.chat-app-list .list-group-item {
  background: transparent;
}
.chat-ready .conversation-list {
  max-height: 800px;
}
.chat-ready .spinner {
  width: 70px;
  text-align: center;
}
.chat-ready .spinner > div {
  width: 10px;
  height: 10px;
  background-color: #E3E3E3;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.chat-ready .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.chat-ready .spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* Opportunities page */
.opport-box .checkbx-detail {
  width: 30px;
}
.opport-box .lable-detail {
  text-align: center;
  width: 20%;
}
.opport-box .member-info {
  padding-right: 10px;
}
.opport-box .member-info h4 {
  font-size: 16px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.opport-box .member-info p {
  font-size: 13px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Product page */
.product-list-box {
  position: relative;
}
.product-list-box img {
  max-width: 220px;
  height: auto;
  margin: 0 auto;
  display: block;
}
.product-list-box .product-action {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
}
.product-list-box .detail {
  background: #f4f8fb;
  padding: 20px;
  margin: 0px -10px -10px;
}
.product-list-box .detail h4 a {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 600;
}
.product-list-box .detail .rating ul {
  margin-left: 0px;
}
.product-list-box .detail .rating ul li {
  padding: 0px;
}
.product-list-box .detail .rating ul li a {
  color: #ffbd4a;
  font-size: 15px;
  margin-bottom: 0;
  padding-right: 4px;
}
.product-list-box:hover .product-action {
  display: block;
}
/* =============
   Accounts pages
============= */
.account-pages {
  background: url("../images/agsquare.png");
  position: absolute;
  height: 100%;
  width: 100%;
}
.wrapper-page {
  margin: 5% auto;
  position: relative;
  width: 420px;
}
.wrapper-page .card-box {
  border: 1px solid rgba(54, 64, 74, 0.1);
}
.panel-pages {
  border-radius: 6px;
}
.panel-pages .panel-body {
  padding: 30px;
}
.panel-pages .panel-heading {
  -moz-border-radius: 6px 6px 0px 0px;
  -webkit-border-radius: 6px 6px 0px 0px;
  border-radius: 6px 6px 0px 0px;
  padding: 40px 20px;
  position: relative;
}
.panel-pages .panel-heading h3 {
  position: relative;
  z-index: 999;
}
.user-thumb {
  position: relative;
  z-index: 999;
}
.user-thumb img {
  height: 88px;
  margin: 0px auto;
  width: 88px;
}
.ex-page-content .text-error {
  color: #252932;
  font-size: 98px;
  font-weight: 700;
  line-height: 150px;
}
.ex-page-content .text-error i {
  font-size: 78px;
  padding: 0px 10px;
}
/* signup-signin-page */
.signup-signin-page {
  width: auto;
}
