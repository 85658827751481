/*
Template Name: Ubold Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Responsive
*/
* { box-sizing: border-box; }
@media only screen and (max-width: 6000px) and (min-width: 700px) {
  .wrapper.right-bar-enabled .right-bar {
    right: 0;
    z-index: 99;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .email-msg {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }
}

@media screen and (min-width: 1900px) {
  .col-xl-3 {width:25% !important;}
}


@media (max-width: 767px) {
  #slotOptimiseDiv{
    background: #21a548;
  }
  body {
    overflow-x: hidden;
  }
  .mobile-sidebar {
    left: 0px;
  }
  .mobile-content {
    left: 250px;
    right: -250px;
  }
  .wrapper-page {
    width: 90%;
  }
  .wizard > .steps > ul > li {
    width: 100%;
  }
  .wizard > .content {
    padding: 0px !important;
  }
  .wizard > .content > .body {
    float: none;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0%;
  }
  .font-14{
    font-size: 14px !important;
  }
  .theme-color{
    color:#95c53e
  }
  .ag_grid_header_green{
    background-color: #f3f9ec;
  }
  .margin-top-1{
    margin-top: 1% !important;
  }
  .width-50{
    width:50%
  }
  .wizard.vertical > .steps {
    display: inline;
    float: none;
    width: 100%;
  }
  .wizard.vertical > .content {
    display: inline;
    float: none;
    margin: 0%;
    width: 100%;
  }
  .justify-left{
    justify-content: left !important;
  }
  .navbar-nav .open .dropdown-menu {
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    left: auto;
    position: absolute;
    right: 0;
  }
  .todo-send {
    margin-top: 10px;
    padding-left: 15px;
  }
  .chat-inputbar {
    padding-left: 15px;
  }
  .chat-send {
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .dataTables_wrapper .col-xs-6 {
    width: 100%;
    text-align: left;
  }
}
@media (max-width: 480px) {
  .side-menu {
    z-index: 10 !important;
  }
  .button-menu-mobile {
    display: block;
  }
  .search-bar {
    display: none !important;
  }
}
@media (max-width: 420px) {
  .hide-phone {
    display: none !important;
  }
}
/* Container-alt */
@media (min-width: 768px) {
  .container-alt {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container-alt {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container-alt {
    width: 1170px;
  }
}
@media (max-width: 419px) {
  .topbar-left {
    width: 70px !important;
  }
  .logo .icon-c-logo {
    display: inline-block !important;
  }
  .logo span {
    display: none !important;
  }
  #wrapcontent{
    clear:both;
    float:left;
    margin-top:20px;
  }
  #profileImage img{
    height:50%;
    clear:both;
  }
  #corpProfileImage img{
    height:20%;
    clear:both;
  }
  .contact-card .member-info{
    padding-left:0px;
  }
  .card-boxs{
    height:100%;
    overflow:scroll;
  }
}
.pos-rel{
  position: relative;
}
.pos-t-r {
  position: absolute;
  top: 0%;
  right: 0%;
}
.text-block-top {
    position: absolute;
    top: 0px;
    width: 220px;
    background-color: #ece5e5c9;
    color: #565656;
    padding-left: 60px;
    padding-right: 20px;
    font-size: 13px;
}
.text-block-bottom {
    position: absolute;
    bottom: 0px;
    width: 220px;
    background-color: #ece5e5c9;
    color: #565656;
    padding-left: 30px;
    padding-right: 20px;
    font-size: 13px;
}
.grid {
  max-width: 1200px;
}

/* clearfix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- grid-item ---- */

.grid-sizer,
.grid-item {
  width: 20%;
}

.grid-item {
  width: 220px;
  height: auto;
  float: left;
  cursor: pointer;
  border: 1px solid #d2d2d2;
}

.grid-item--width2 { width:  40%; }
.grid-item--width3 { width:  60%; }

.grid-item--height2 { height: 200px; }
.grid-item--height3 { height: 260px; }
.grid-item--height4 { height: 360px; }

.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.ng-table-counts{
  display:none;
}
.ng-table-pager{
  text-align:right;
}
.has-error{
  color:red !important;
}
.card-boxs {

    padding-bottom: 0px;
    height:250px;

}

.activTabs{
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}
.inActiveTab{
  border-bottom: 1px solid #ddd;
}
.activeColor{
  color:#8BC53F !important
}

.h-50{
  height: 50px;
}
.appointment-modal-btn-align{
  padding-left: 2px;
  margin-bottom: 15px;
}
.padding-l-r{
  padding-left: 0px;
  padding-right: 0px;
}
.w-a{
  width: auto!important;
}
.h-a{
  height: auto!important;
}
.p-r-0{
  padding-right: 0px!important;
}
.p-l-0{
  padding-left: 0px!important;
}
.b-n{
  border: none!important;
}
.w-100{
  width: 100%!important;
}
.w-85{
  width: 85%!important;
}
.multiSelect{
  background-color: white!important;
  color: black!important;
  border-color: #f6f6f6!important;
  width: 390px;
}
.margin-l-r-10{
  margin-left: 10px;
  margin-right: 10px;
}
.margin-l-r-12{
  margin-left: 12px;
  margin-right: 12px;
}
.table-responsive_col{
   max-height: 125px
}
.text-line {
white-space:pre-line !important;
}
.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
    margin-left: 3px;
    margin-top: 0;
}
.card-height{
  min-height: 250px
}
.word-rap{
white-space: pre;
}
.no-word-rap{
    white-space: nowrap !important;
}
.custom-price-height{
    height: 43px;
}
.textCenter{
  text-align:center;
}
.cus-checkbox{
    left: 15px;
    position: absolute;
    top: 14px;
    width: auto;
}
 .lt {
    font-size: 25px !important;
    color: #CCCCCC !important;
    cursor: pointer;
}
.bidinp {
    width: 50px !important;
    text-align: center !important;
    border: none !important;
    font-size: 14px !important;
    background-color: transparent;
     position: relative;
    top: -3px;
}
.company-product .single-product,
.company-portfolio .single-product {
    position: relative;
    /*height: 50%;*/
    margin-bottom: 30px;
    background-color: #f8f8f8;
    border: 1px solid #dddddd;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 2px #dddddd;
    /*width: 50%*/
    text-align: center;
}

.company-product .single-product figure,
.company-portfolio .single-product figure {
    position: relative;
    margin: 0;
    padding: 1px;
    /*width: 100%;*/
    /*?\height: 200px;*/
    border-bottom: 1px solid #dddddd;
}

.company-product .single-product figure img,
.company-portfolio .single-product figure img {
    width: 100px;
    height: 100px;
}

.company-product .single-product figure figcaption,
.company-portfolio .single-product figure figcaption {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(253, 198, 0, 0.8);
}

.company-product .single-product figure figcaption a,
.company-portfolio .single-product figure figcaption a {
    position: relative;
    padding: 6px 10px 6px 33px;
    border: 1px solid #333333;
    border-radius: 3px;
    font-weight: bold;
}

.company-product .single-product figure figcaption a .fa,
.company-portfolio .single-product figure figcaption a .fa {
    position: absolute;
    left: 0;
    top: 0;
    height: 29px;
    font-weight: bold;
    border-right: 1px solid #333333;
}

.company-product .single-product figure figcaption a:hover,
.company-portfolio .single-product figure figcaption a:hover {
    background: #333333;
    color: #fdc600;
}

.company-product .single-product figure figcaption .bookmark,
.company-portfolio .single-product figure figcaption .bookmark {
    position: relative;
    top: 70px;
}

.company-product .single-product figure figcaption .bookmark .fa,
.company-portfolio .single-product figure figcaption .bookmark .fa {
    padding: 7px 9px;
}

.company-product .single-product figure figcaption .read-more,
.company-portfolio .single-product figure figcaption .read-more {
    position: relative;
    top: 90px;
}

.company-product .single-product figure figcaption .read-more .fa,
.company-portfolio .single-product figure figcaption .read-more .fa {
    padding: 7px 11px;
}

.company-product .single-product:hover figure figcaption,
.company-portfolio .single-product:hover figure figcaption {
    display: block;
}

.company-product .single-product h4,
.company-portfolio .single-product h4,
.company-product .single-product h5,
.company-portfolio .single-product h5 {
    margin: 0;
    padding: 5px 30px 0;
    text-transform: capitalize;
}

.company-product .single-product h4 a:hover,
.company-portfolio .single-product h4 a:hover,
.company-product .single-product h5 a:hover,
.company-portfolio .single-product h5 a:hover {
    color: #666666;
}

.company-product .single-product h4,
.company-portfolio .single-product h4 {
    padding-top: 15px;
}
th.text-left{
    text-align:left !important;
}
th.text-right{
    text-align:right !important;
}
#loading-bar-spinner {

  top: 50%;
  left: 50%;
  background-color: rgba(70,70,70,1);
  width:50px;
  height:50px;
  border-radius:50%;
}

#loading-bar .bar {
  background: #fff;
  height: 4px;
}

#loading-bar-spinner .spinner-icon {
  width: 24px;
  height: 24px;

  border: solid 2px transparent;
  border-top-color: #fff;
  border-left-color: #fff;

  position:fixed;
  top:52%;
  left:51%;

}

.changecursor{
  cursor: pointer;
}
.cards-bottom{
  padding-bottom: 0px;
}
.p-b-20{
  padding-bottom: 20px;
}
.m-10{
  margin: 10px;
}
#sidebar-menu ul ul a{
  padding: 6px 20px 7px 0px;
}
.err-red{
  color:red !important;
}
.err-gray{
  color:gray;
}

.growl-container > .alert-success.icon {
    background-color: #eee !important;
    color: green !important;
}
.growl-container > .alert-error.icon {
    background-color: #eee !important;
    color: red !important;
}
.dataTables_length{
    display: none;
    visibility: hidden;
  }
  .dataTables_paginate{
    display: none !important;
    visibility: hidden !important;

  }
  .dataTables_empty{
     display: none !important;
    visibility: hidden !important;
  }
  .dataTables_info{
    display: none;
    visibility: hidden;
  }
  .dataTables_filter{
    display: none;
    visibility: hidden;
  }
  .comment{
    color: red !important;
  }
  .text-muted {
    padding-right: 55% !important;
  }
  .btn_m{
    margin-top: -19% !important;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
  .col_p {
    padding: 10px 10px;
  }

 .rotate90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.scrapImage {
  max-width: 420px;
}

.sidebar-inner {
  /* overflow-y: scroll !important; */
}

.color-red {
  background-color: red;
  color: white;
}

.color-brown {
  background-color: #8B4513;
  color: white;
}

.color-purple {
  background-color: #6e63b3fa;
  color: white;
}
.rating {
    color: #a9a9a9;
    margin: 0;
    padding: 0;
}
ul.rating {
    display: inline-block;
}
.rating li {
    list-style-type: none;
    display: inline-block;
    padding: 1px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.rating .filled {
    color: rgba(189, 216, 77, 1);
}
.color-blue {
  background-color: blue;
  color: white;
}

.color-green {
  background-color: green;
  color: white;
}

.color-black {
  background-color: black;
  color: white;
}
.ratio {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
.color-yellow {
  background-color: yellow;
  /*color: white;*/
}

.color-orange {
  background-color: orange;
  color: white;
}

.red-text {
  color: red;
}
.black-text {
  color: black;
}
@keyframes glow {
    to {
        text-shadow: 0 0 5px #f76363;
    }
}

.expired-anim>i {
    -webkit-animation: glow .5s infinite alternate;
    color: red;
}

.heightScroll {
  height: 430px;
}

#sidebar-menu {
  padding-top: 0px;
}

.content-page > .content {
  padding: 0px 5px 0px 5px;
}

.page-title {
  line-height: 30px;
}

.breadcrumb {
    margin-bottom: 0px;
    line-height: 30px;
}

table.dataTable.no-footer {
  border: none;
}

.consumer-appointments>.dataTables_wrapper {
  max-height: 600px;
  overflow-y: scroll;
}


.cropArea {
  background: #E4E4E4;
  overflow: hidden;
  width:500px;
  height:350px;
}

form .progress {
  line-height: 15px;
}
.progress {
  display: inline-block;
  width: 100px;
  border: 3px groove #CCC;
}
.progress div {
  font-size: smaller;
  background: orange;
  width: 0;
}
.editProfileIcon {
  position: relative;
  left: -45px;
  top: 25px;
  display: block;
}

.editProfileIcon:hover {
  display: block;
}


.widget-panel-15pad {
  padding: 15px 20px !important;
}

.content-page {
  /* background-color: #f2f2f2!important; */
  background-color: #ffffff!important;
}
.checkbox label::before {
  border: none;
  background-color: transparent;
}
.checkbox input[type="checkbox"]{
  opacity: unset;
}
.multiselect-parent > button{
  width: 161px;
  background-color: white !important;
  color: #181717 !important;
  border-color: #f5f5f5 !important;
}
.multiselect-parent{
  width: 161px;
  background-color: white !important;
  color: #181717 !important;
  border-color: #f5f5f5 !important;
}

.table-lock > thead:first-child > tr:first-child > th:first-child {
    position: absolute;
    display: inline-block;
    /*background-color: red;*/
    /*height: 100%;*/
}

.table-lock > tbody > tr > td:first-child {
    position: absolute;
    display: inline-block;
    background-color: white;
    border: none;
    /*height: 100%;*/
}

.table-lock > thead:first-child > tr:first-child > th:nth-child(2) {
    padding-left: 80px;
    padding-right: 40px;
}

.table-lock > tbody > tr > td:nth-child(2) {
    padding-left: 170px !important;
    padding-right: 50px !important;
}

/*Start css for preventing flickering*/
.slide-animation {
  width: 100%;
  position: absolute;
}

.slide-animation.ng-enter {
  animation: slideInRight 0.8s;
  -webkit-animation: slideInRight 0.8s;
  -moz-animation: slideInRight 0.8s;
  -ms-animation: slideInRight 0.8s;
}

.slide-animation.ng-enter { display:none; }
.slide-animation.ng-enter-active { display:block; }

.slide-animation.ng-leave {
  animation: slideOutLeft 0.8s;
  -webkit-animation: slideOutLeft 0.8s;
  -moz-animation: slideOutLeft 0.8s;
  -ms-animation: slideOutLeft 0.8s;
}
/*End css for preventing flickering*/

.valign{
  display: flex;
  align-items: center;
}
.halign{
  display: flex;
  justify-content: center;
}
.custom-dropdown-color, .custom-dropdown-color:active, .custom-dropdown-color:hover,
.custom-dropdown-color:focus{
  background-color: #139235;
  border: 1px solid #139235;
  color: #fff;
}
.no-internet{
  position: fixed;
  bottom: 0;
  padding: 20px;
  background-color: #f05050;
  color: #fff;
  right: 0px;
  border: 0px solid #f05050;
  border-radius: 2px;
}

.whole-saler{
  background: #fff;
  padding: 0px;
  margin: 0px;
}
.whole-saler li{
  list-style: none;
  padding: 10px;
  border-bottom: 1px solid rgba(158, 158, 158, 0.27);
  cursor: pointer;
}
.comm-search-box{
  width: 100%;
}
.comm-search-box input{
  border-radius: 4px;
  width: 100%;
  height: 35px;
  border: 1px solid rgba(158, 158, 158, 0.32);
  padding: 10px;
  font-weight: 500;
}
.dropdown-comm{
    border-radius: 2px;
    width: 50%;
    background: white;
    height: 30px;
}
.p-t-40{
  padding-top: 40px;
}
.p-t-30{
  padding-top: 30px !important;
}
.p-t-15{
  padding-top: 15px !important;
}
.m-0{
  margin: 0px;
}
.update-icon{
  width: 14px;
  margin-bottom: 2px;
}
.df{
  display: flex;
}
.dib{
  display: inline-block;
}
.m-t-10{
  margin-top: 10px;
}
.p-t-b-2{
  padding-top: 2px;
  padding-bottom: 2px;
}
.m-t-4-r-15 {
  margin-top: 4px !important;
  margin-right: 15px !important;
}
#floating-panel {
  position: absolute;
  top: 75px;
  left: 60%;
  z-index: 5;
  background-color: #fff;
  text-align: center;
}
.p-0-4{
  padding: 0px 4px;
}
.modal-xl {
    width: 100%;
    height: 100%!important;
    margin-top: 10px;
    margin-bottom: 0px;
}
.m-p-modal{
  margin-right: 10px;
  padding: 5px;
}
.modal-xl .modal-content{
  padding: 15px !important;
}
.search-close{
  font-size: 25px;
  position: relative;
  left: -30px;
  top: 5px;
  cursor: pointer;
}
.input-50 input{
  width: 50%;
}
.comm-autocomplete{
  position: absolute;
  z-index: 9;
  padding: 0;
  border: 1px solid rgba(158, 158, 158, 0.21);
}
.p-t-20{
  padding-top: 20px;
}
.p-t-10{
  padding-top: 10px;
}
.p-t-8{
  padding-top: 8px;
}
.p-l-5{
  padding-left: 5px !important;
}
.p-t-6{
  padding-top: 6px !important;
}
.p-l-10{
  padding-left: 10px !important;
}
.p-l-20{
  padding-left: 20px !important;
}
.p-l-15{
  padding-left: 15px !important;
}
.p-l-25{
  padding-left: 25px !important;
}
.p-r-10{
  padding-right: 10px !important;
}
.p-r-20{
  padding-right: 20px !important;
}
.m-t-0 {
  margin-top: 0px !important;
}
.caret-up-down{
  font-size: 20px;
  padding: 5px;
}
.common-mar-bottom-not-last > div:not(:last-child){
  margin-bottom: 5px;
}
.p-15{
  padding: 15px;
}
.valign{
  display: flex;
  align-items: center;
}
.p-10{
  padding: 10px;
}
.ac{
  text-align: center;
}
.f-w-600{
  font-weight: 600;
}
.f-s-i{
  font-style: italic;
}
.c-p{
  cursor: pointer;
}
.l-c{
  color: #6a6ae8;
}
.activities-date-time{
  font-weight: 100;
  color: rgba(0,0,0,0.4);
}
.p-5{
  padding: 5px;
}
.b-0{
  border: 0px;
}
.date-input input, .date-input input:active, .date-input input:focus{
  height: 35px;
  border: 1px solid rgba(158, 158, 158, 0.3);
  border-radius: 4px;
}
/*.arrived-color{
  background: #848fcc;
  color: #fff;
}
.animate-arrived-color{
  background: #b0b5d2;
  color: #fff;
}*/

.arrived-color {
  background: #848fcc;
  color: #fff;
  animation: color 2s infinite both;
}

@keyframes color {
  59.999% {
    background: #848fcc;
    color: #fff;
  }
  60% {
    background: #b0b5d2;
    color: #fff;
  }
  70% {
    background: #b0b5d2;
    color: #fff;
  }
}
.timeline-badge-index {
    position: absolute;
    display: block;
    border-radius: 100%;
    border: 1px solid #fff;
    background: #000000;
    /*padding-top: 2px;*/
    /*padding-bottom: 2px;*/
    padding-left:6px;
    padding-right:6px;
    color: #fff;
    text-align:center;
    font-weight: bold;
    margin-top: 3px;
}
.badge-direction{
    margin-top: -2px;
    margin-left: 4px;
    text-transform: uppercase;
    background-color: #5fbeaa;
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 10px;
}

.location{
  color: #fff;
  padding: 2px;
  font-size: 10px;
  overflow: hidden;
}
.consumer-marker-bg{
  background-color: #4CAF50;
}
.slot-count-dashboard{
  width: 540px;
  float: right;
  margin-right: 0px;
}
.slot-text{
  font-size: 17.5px;
  font-weight: 600;
}
.labels {
  color: white;
  background-color: #4CAF50;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 12px;
  text-align: left;
  width: 148px;
  white-space: nowrap;
  padding: 2px;
  padding-left: 5px;
  overflow: hidden;
  border-radius: 4px;
  border:1px solid white;
}
.sell-report-labels {
  color: white;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 12px;
  text-align: left;
  font-weight: bold;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid white;
}
.gm-style-iw-t{
  top: -15px !important;
  left: 38px !important;
}
.toggle-active, .toggle-inactive {
  font-size: 26px;
  cursor: pointer;
}
.toggle-active, .toggle-inactive {
  font-size: 26px;
  cursor: pointer;
}
i.toggle-active {
  color: #5cb85c;
}
i.toggle-inactive {
  color: #d9534f;
}
.labels-confirmation {
  color: white;
  background-color: #4CAF50;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 12px;
  text-align: left;
  width: 83px;
  white-space: nowrap;
  padding: 2px;
  padding-left: 5px;
  overflow: hidden;
  border-radius: 4px;
  border:1px solid white;
}
.labels-agent {
  color: white;
  background-color: #4CAF50;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 12px;
  text-align: left;
  width: 92px;
  white-space: nowrap;
  padding: 2px;
  padding-left: 5px;
  overflow: hidden;
  border-radius: 4px;
  border:1px solid white;
}
.labels-start {
  color: white;
  background-color: #4CAF50;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 12px;
  text-align: center;
  width: 45px;
  white-space: nowrap;
  padding: 2px;
  overflow: hidden;
  border-radius: 4px;
  border:1px solid white;
}
div.myModal > div.modal-dialog {
  width: 1250px !important;
}
.textLabel{
  text-align: left;
}
.orderow{
  width: calc(100vw / 9);
}
.accRoute{
  border: 1px solid rgba(0,0,0,.125);
  background-color: rgba(0,0,0,.03);
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.selectStyleCustom{
  width: 98% !important;
}
.selectStyleCustomFalse{
  width: 85% !important;
}
.closed-marker-bg{
  margin-top: 20px;
  background-color: rgba(255,255,0,0.3);
  margin-top: 20px;
  color: black;
}
.arrived-marker-bg{
  background-color: #FF9800;
}
.margin-t-b-0{
  margin-top: 0px;
  margin-bottom: 0px;
}

/*Login parralax*/
body {
  background:#84dbd7;
}
.scene {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  right: 0;
  border-bottom: px solid #342a2a;
  height: 100%;
}
.scene > div {
  position:absolute;
  bottom:0;
}
.ground {
  width:100%;
  height:30px;
  background:#1d1818;
  z-index:999;
}
.scene > div.clouds {
  width:895px;
  left:50%;
  margin-left:-447px;
  height:253px;
  bottom:250px;
  background:url(https://i.imgur.com/WYfbo0O.png) no-repeat center;
}

.scene div.trees {
  width:908px;
  height:174px;
  background:url(https://nyc3.digitaloceanspaces.com/iesoft/scrapq/src-images/trees.png) no-repeat center;
  z-index:100;
  left:50%;
  bottom:20px;
  margin-left:-454px;
}
.scene div.scrapq1 {
  width: 500px;
  height: 220px;
  background: url(https://nyc3.digitaloceanspaces.com/iesoft/scrapq/scrapq-layer1.png) no-repeat center;
  z-index: 999;
  left: 60%;
  bottom: 30px;
  margin-left: -454px;
}
.scene div.scrapq2 {
  width: 500px;
  height: 220px;
  background: url(https://nyc3.digitaloceanspaces.com/iesoft/scrapq/scrapq-layer2.png) no-repeat center;
  z-index: 999;
  left: 72%;
  bottom: -40px;
  margin-left: -454px;
}
.scene div.grass {
  width:964px;
  height:37px;
  z-index:200;
  left:50%;
  bottom:20px;
  margin-left:-482px;
  background:url(https://nyc3.digitaloceanspaces.com/iesoft/scrapq/src-images/grass.png) no-repeat center;
}
.buildings {
  width:763px;
  height:303px;
  left:50%;
  margin-left:-400px;
  background:url(https://nyc3.digitaloceanspaces.com/iesoft/scrapq/src-images/buildings.png) no-repeat center;
}

html {
  background: #ffffff;
  height: 101%; /*added to show scroll bar in all pages*/
}

.color-transparent-white {
  background-color: rgba(255,255,255,0.9);
}

.z-index-999{
  z-index: 999;
}

.page-radio span{
  font-weight: 500;
  position: relative;
  top: -2px;
}
.page-radio-left > label:not(:first-child){
  margin-left: 5px;
}
.font-weight-600{
  font-weight: 600;
}
.page-radio label{
  margin: 0px;
}
.p-r-15{
  padding-right: 15px;
}
.custom-label label{
  border: 1px solid rgba(158, 158, 158, 0.11);
  padding: 0px 10px;
  border-radius: 4px;
  padding-top: 3px;
  font-size: 13px;
}
.input-hide input{
  display: none;
}
.applly-selected-border{
  border-color: #139235!important;
}
.order-label > label{
  -webkit-flex-direction: row;
  -webkit-flex-wrap: wrap;
}
.comm-mar-left-5-even:nth-child(even){
  margin-left: 5px!important;
}
.m-b-10{
  margin-bottom: 10px;
}
.m-b-0{
  margin-bottom: 0px !important;
}
.comm-pad-left-not-first > div:not(:first-child){
  padding-left: 10px!important;
}

/*pulse marker*/
.pulse-marker {
  margin-top: 20px;
  margin-left: 30px;
  position: relative;
}

.pulse {
  width: 10px;
  height: 10px;
  border: 5px solid #7fd2e6;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #00a6cd;
  z-index: 10;
  position: absolute;
}

.dot {
  position: absolute;
  height: 50px;
  width: 50px;
  top: -25px;
  left: -25px;
  z-index: 2;
  opacity: 0;
  border: 10px solid rgba(0,166,205,1);;
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  -webkit-animation: flash 2s ease-out;
  -moz-animation: flash 2s ease-out;
  animation: flash 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-moz-keyframes pulse {
  0% {-moz-transform: scale(0);opacity: 0.0;}
  25% {-moz-transform: scale(0);opacity: 0.1;}
  50% {-moz-transform: scale(0.1);opacity: 0.3;}
  75% {-moz-transform: scale(0.5);opacity: 0.5;}
  100% {-moz-transform: scale(1);opacity: 0.0;}
}

@-webkit-keyframes flash {
  0% {-webkit-transform: scale(0);opacity: 0.0;}
  25% {-webkit-transform: scale(0);opacity: 0.1;}
  50% {-webkit-transform: scale(0.1);opacity: 0.3;}
  75% {-webkit-transform: scale(0.5);opacity: 0.5;}
  100% {-webkit-transform: scale(1);opacity: 0.0;}
}
.custom-select{
  background: #fff;
  border-color: #e0e0e0;
  border-radius: 4px;
  padding: 2px
}
.custom-class-for-label{
  border: 1px solid rgba(158, 158, 158, 0.24)!important;
  border-radius: 5px!important;
  background: rgb(255, 255, 255)!important;
  color: #4CAF50!important;
  font-size: 9px;
  padding: 3px;
  width: 150px!important;
}
.custom-class-for-old-label{
  border: 1px solid rgba(158, 158, 158, 0.24)!important;
  border-radius: 5px!important;
  background: rgb(255, 255, 255)!important;
  font-size: 9px;
  padding: 3px;
  width: 150px!important;
}
.show-all-check{
  font-weight: 500;
  margin-left: 5px;
  position: relative;
  top: -1px;
}
.readonly-bg{
  background-color: rgba(175, 170, 170, 0.14);
}
.height-35{
  height: 35px;
}
.cursor-link:hover{
 color:#00a6cd !important
}

.agent-location-marker{
  height: 20px;
  width: 20px;
  background: #fff;
  border: 6px solid red;
  border-radius: 50%;
  z-index: -1;
}
.form-control{
  height: 35px;
}
.font-14{
  font-size: 14px !important;
}
.font-12{
  font-size: 12px !important;
}
.cursor{
  cursor: pointer !important;
}

#sidebar-menu > ul > li > a {
  color:  #262223;
  display: inline-block!important;
  padding: 9px 10px;
  /* margin: 0px 0px; */
  border-left: 3px solid #ffffff;
  /* text-transform: uppercase; */
  font-size: 14px;
  letter-spacing: 0.1px;
  /* color: #666; */
  background-color: #F8F8F8;
  width: 100%;
}

.side-menu.left {
  background-color: #F8F8F8;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular_0.otf') format('otf'), url('../fonts/Roboto-Condensed_2.ttf') format('ttf');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Light_1.ttf') format('ttf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Thin_1.ttf') format('ttf');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold_1.ttf') format('ttf'), url('../fonts/Roboto-BoldCondensed_0.ttf') format('ttf');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.ttf') format('ttf');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Thin.ttf') format('ttf');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Light.ttf') format('ttf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.ttf') format('ttf');
  font-style: normal;
  font-weight: bold;
}

body {
  font-family: 'Lato', 'Roboto', serif;
}

.content-page {
    /* background-color: #f2f2f2 !important; */
    background-color: #ffffff!important;
    min-height: 680px!important;
}

select.custom-select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23ffffff%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E");
  padding-right: 25px;
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* color: white; */
  border: none;
  /* background-color: #21a548; */
}

select.custom-select::-ms-expand {
  display: none;
}

.fc-state-default {
    background-color: #f5f5f5;
    background-image: none !important;
    background-repeat: repeat-x;
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #333;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

/*.side-menu {
    width: 190px !important;
}*/

/*.content-page {
    margin-left: 180px !important;
    margin-bottom: 0px !important;
}*/

.content-page>.content {
    margin-bottom: 0px !important;
}

.navbar-default {
    background-color: #ffffff;
}

.dt-loading {
  display: none !important;
}
.m-lr-10{
  margin-left: 10px;
  margin-right: 10px;
}
.custom-reset-button{
  width: 90px;
  padding: 7px;
  text-align: center;
  background: #4da648;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid #4da648;
  border-radius: 4px;
  cursor: pointer;
}
.custom-back-button{
  width: 70px;
  border: 1px solid #d8d0d0;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}
.custom-back-button span{
  padding-left: 5px;
}
.acquisitions-details img{
  width: 100px;
}
.pad-left-5-not-last > div:not(:first-child){
  padding-left: 5px;
}
.user-title {
  font-size: 18px;
  color: #5fbeaa;
  padding-bottom: 10px;
}
.comm-icon {
  font-size: 20px;
  color: #5fbeaa;
}
.comments > li {
  font-size: 13px;
  list-style: none;
}
.user-cat, .user-mob {
  padding-left: 5px;
  color: #73879C;
}
.approve{
  width: 90px;
  border: 1px solid #67a649;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  background: #67a649;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  margin: auto;
}
.comments-time {
  padding-left: 10px;
  font-size: 12px;
  font-style: oblique;
}
.color-5fbeaa {
  color: #5fbeaa;
}
.pad-bottom-5-not-first > div:not(:first-child){
  padding-bottom: 5px;
}
.frequency{
  width: fit-content;
  background: #1b191921;
  color: #000000a1;
}
.m-a{
  margin: auto;
}
.not-avail{
  font-size: 80px;
  color: #f54646;
}
.max-100{
  max-width: 100%;
}
.bdr-l{
  border-left: 1px solid #9e9e9e36;
}
.consumer-info-img{
  max-height: 300px
}
.consumer-info i{
  color: #787878;
}
.custom-box{
  width: 140px;
  border: 1px solid white;
  padding: 10px;
  border-radius: 4px;
  background: white;
}
.statis-text{
  color: gray;
  font-size: 16px;
  text-align: center;
  margin: 0px;
}
.statis-count{
  color: red;
  font-size: 30px;
  text-align: center;
  margin: 0px;
}
.bg-f3f3f2{
  background: #f3f3f2;
}
.gap-20{
  width: 20px;
}
.p-25 {
  padding: 5px 25px;
}
.statis-border{
  border: 1px solid #dcdada!important;
}
.no-statis{
  margin: 0px;
  font-size: 16px;
}
.tar{
  text-align: right;
}
.b-0{
  border: 0px solid;
  border-radius: 0px;
}
.mla{
  margin-left: auto;
}
.mr30{
  margin-right: 30px;
}
.ml25 {
  margin-left: 25px;
}
.ml15 {
  margin-left: 15px;
}
.ml30{
  margin-left: 30px;
}
.pad-left-10-not-last > span:not(:first-child){
  padding-left: 10px;
}
.sort-icon i{
  font-size: 18px;
}
.acquisition-statistic table{
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.acquisition-statistic td, .acquisition-statistic th{
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.acquisition-statistic tr:nth-child(even){
  background-color: #dddddd;
}
.p-0{
  padding: 0px;
}
.pad-mar-20-not-first > div:not(:last-child){
  margin-bottom: 20px;
}

.reporting-map{
  width: 130px;
  text-align: center;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #d6d3d3;
}
.activate-map{
  background: #67a649;
  border: 1px solid #67a649;
  color: #fff;
}
.mar-left-10-not-last > div:not(:first-child){
  margin-left: 10px;
}
.graph-color-code{
  width: 20px;
  height: 20px;
  margin-left: auto;
  border: 0px;
  border-radius: 50%;
}
.color-register-user{
  background: radial-gradient(ellipse at center, rgba(217,41,21,1) 7%, rgba(240,240,93,1) 34%, rgb(204, 240, 93) 60%, rgba(151,240,93,1) 74%, rgba(113,240,93,1) 80%);
}
.color-interested-user{
  background: radial-gradient(ellipse at center, rgb(250, 200, 122) 7%, rgb(255, 255, 255) 34%, rgb(250, 200, 120) 60%, rgb(255, 255, 255) 74%, rgb(255, 255, 255) 80%);
}
.color-notinterested-user{
  background: radial-gradient(ellipse at center, rgb(140, 135, 243) 7%, rgb(255, 255, 255) 34%, rgb(149, 130, 231) 60%, rgb(147, 142, 243) 74%, rgb(255, 255, 255) 80%);
}
.select-month-list{
  margin-left: 10px;
  width: 40%;
  background: #fff;
  border: 1px solid #e8e0e0;
  padding: 2px;
  border-radius: 2px;
}
.color-total-user-install{
  background-color: #A0B421
}
.color-active-device-install{
  background-color: #F0AB05;
}
.color-uninstalls{
  background-color: #FF0000;
}

.table.dataTable tbody td {
  padding-top:5px;
  padding-bottom:5px;
}

ul.card-list {
  padding-left: 0px;
  min-height: 40px;
}

.card-title {
  font-size: 12px;
  font-weight: 600;
  margin-left:10px;
}

.card-description {
    font-size: 10px;
    margin-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 22ch;
    overflow: hidden;
    margin-bottom: 3px;
}
.items-xeditable {
    padding-right: 0px;
    padding-left: 0px;
}
.items-add-btn {
    margin-left: 25px;
    height: 35px;
}
.items-header{
    text-align: center;
    font-weight: bold;
    height: 35px;
    background-color: #f4f4f4;
}
.items-tr{
    height: 35px;
}
#lynk-booking-starttime {
  width: 100%;
}

.late-closed {
  color: red;
}

@-webkit-keyframes color-change-late {
    0% { color: red; }
    50% { color: blue; }
    100% { color: red; }
}
@-moz-keyframes color-change {
    0% { color: red; }
    50% { color: blue; }
    100% { color: red; }
}
@-ms-keyframes color-change {
    0% { color: red; }
    50% { color: blue; }
    100% { color: red; }
}
@-o-keyframes color-change {
    0% { color: red; }
    50% { color: blue; }
    100% { color: red; }
}
@keyframes color-change {
    0% { color: red; }
    50% { color: blue; }
    100% { color: red; }
}

.late-not-closed {
  font-weight:700;
  width:90px;
  -webkit-animation: color-change 1s infinite;
    -moz-animation: color-change 1s infinite;
    -o-animation: color-change 1s infinite;
    -ms-animation: color-change 1s infinite;
    animation: color-change 1s infinite;
}

.late-not-closed:before {
  content: "🔔";
  font-weight:700;
}

.radio-inline-alignment {
    height: 35px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.line-height-normal {
    line-height: normal;
}
.inventory-box{
  border: 1px solid black;
  margin-top: 20px;
}
.boxes-row{
  display: grid;
  grid-template-columns: repeat(3, minmax(0,1fr));
  background-color: white;
  grid-column-gap: 20px;
}
/*!important tags used in below classes to override widget-panel css*/
.warehouse-inventory-box{
  padding-left: 20px;
  /* padding-top: 20px; */
  padding-bottom: 20px;
}
.dot-indicator{
  padding: unset !important;
  font-size: 15px !important;
  right: unset !important;
  background: unset !important;
}
.dots-div{
  height: 25px;
}
.dots-div i{
  line-height: unset !important;
}
.inner-dot-div{
  padding-left: 0px;
  padding-right: 0px;
}
.dot-indicator-date{
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1111px) and (min-width: 768px) {
  .dot-indicator-date{
    margin-left: 50%;
    margin-top: -1px;
  }
  .dots-div{
    height: 10px;
    margin-bottom: 10px;
  }
  .inner-dot-div i span{
    display: none;
  }
  .dot-indicator-div i span{
    display: none;
  }
}
@media (max-width: 1260px) and (min-width: 1051px) {
  .warehouse-inventory-box{
    padding-right: 0px;
  }
}
.adjustPadding{
  padding-bottom: 15px;
}
.warehouseType{
  color:#21a548 !important;
}
.productType{
  color:#21a548 !important;
  float: right;
  margin-top: -10px !important;
}
.warehouseBox{
  color:black!important;
  font-size: larger;
}
.inventoryCards{
  height: 75px;
  padding: 10px 15px;
}
.so-to-cards{
  height: 85px;
  width: 224px;
  border: 1px solid #efefef;
  padding: 10px 15px;
}
@media (max-width: 1080px) and (min-width: 768px) {
  .inventoryCards,
  .so-to-cards {
    height: 65px;
    font-size: 12px;
    padding-left: 5px;
    padding-top: 5px;
    padding-right: unset;
    padding-bottom: unset;
  }
  .inventoryCards h4,
  .so-to-cards h4 {
    font-size: 95%;
  }
  .so-to-cards {
    height: 75px;
    width: 150px;
  }
  .w-60{
    width: 100% !important;
  }
}

.priceCapsuleTableBody{
  overflow-y: scroll;
  height: 200px;
  scrollbar-width: none; /*hide scrollbar in other browsers*/
}

.priceCapsuleTableBody::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /*make scrollbar */
}

.linkToUsers{
  text-decoration: underline;
  color: blue;
}

/* mouse over link */
.linkToUsers:hover {
  color: hotpink;
}

/* unvisited link */
.linkToUsers:link {
  color: red;
}

/* visited link */
.linkToUsers:visited {
  color: green;
}

/* selected link */
.linkToUsers:active {
  color: blue;
}

.grey-border{
  border: 1px solid #ccc;
}

.warehouseNameBox{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1259px) and (min-width: 768px) {
  .inventoryDetailsTheader th{
    padding: 1px !important;
    font-size: 10px;
  }
}

/* org chart */
#chart{
  text-align: center;
  margin-bottom: 5%;
  line-height: normal;
}
#chart .parent {
    display: table;
    /* width: 200px; */
    padding: 2px 0px 0px 0px;
    width: 100%;
}
#chart .text {
    font-size: 10px;
    display: table;
    text-align: left;
}
#chart .text h4{
    font-size: 13px;
    padding: 4px 0px 0px 4px;
}

#chart .text div{
    padding: 2px 0px 2px 4px;
}
#chart .google-visualization-orgchart-node-medium {
    width: 180px;
    min-width: 180px;
    max-width: 180px;
}
#chart .google-visualization-orgchart-node {
    background: white;
    padding: 2px 0px 0px 0px;
    cursor: pointer;
}
#chart .google-visualization-orgchart-node .SCRAPQ{
    border-bottom: 10px solid #21a548;
}
#chart .google-visualization-orgchart-node .MASTERFRANCHISE{
    border-bottom: 10px solid purple;
}
#chart .google-visualization-orgchart-node .PARTNER{
    border-bottom: 10px solid skyblue;
}
#chart .google-visualization-orgchart-node .AGENT{
    border-bottom: 10px solid yellow;
}
#chart .google-visualization-orgchart-linenode{
  padding: 2px !important;
}
.chart-row{
  overflow: auto;
}
.chart-row .chart-div table tbody{
  display: table-caption;
}
#chart table{
    border-spacing:0;
    border-collapse:separate;
}
#chart .google-visualization-orgchart-table * {
    margin: 0;
    padding: 0;
}
.w-50{
  width: 50%;
}
.w-60{
  width: 60%;
}
.box-div-1{
  display: inline-block;
  float: left;
}
.box-div-2{
  display:inline-block;
  text-align: end;
}
.baa-cat{
    border: 1px solid #9E9E9E;
    border-radius: 50%;
    width: 2vw;
    height: 2vw;
    margin: auto;
}
.valign{
    display: flex;
    align-items: center;
}
.cat-imgs{
    width: 1.5vw !important;
    mix-blend-mode: multiply;
}
.category-input select{
  width: 90%;
}
.quantity-input input{
  width: 90%;
}
.no-click {
  pointer-events: none;
}
.p-t-l-1{
  padding-left: 11px;
  padding-top: 1px;
}
/* ag-grid styles */

.ag-theme-alpine .ag-header-row {
    height: 40px !important;
  }
.ag-theme-alpine .ag-paging-panel {
  height: 43px !important;
}
.ag-theme-alpine .ag-root-wrapper {
  border-right: none !important;
  border-left: none !important;
}
.ag-header-cell-label {
  color: yellowgreen;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Lato',serif!important;
  font-weight: 400;
}
.ag-cell {
  font-size: 14px;
  font-family: 'Lato',serif;
}

.m-t-22{
  margin-top: 22px !important;
}
.ag-header-cell-label {
   justify-content: center;
}

/*tabs colors and highlighting*/
.ag-theme-alpine .ag-tab-selected {
    color: #449c45 !important;
    border-bottom-color:yellowgreen !important;
}

/*icons colors*/
.ag-theme-alpine .ag-icon {
    color: #449c45 !important;
}

/*ag-grid input fields*/
.ag-theme-alpine input[class^=ag-][type=text]:focus, .ag-theme-alpine .ag-picker-field-wrapper:focus {
    box-shadow: 0 0 2px 0.1rem #449c45!important;
}

/*ag-grid buttons*/
.ag-theme-alpine .ag-standard-button {
    border: 1px solid #449c45 !important;
    color: #449c45 !important;
}

/*ag-grid checkboxes*/
.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked:after {
    color: #449c45 !important;
}

/*ag-grid radio buttons*/
.ag-theme-alpine .ag-radio-button-input-wrapper:focus-within {
    box-shadow: 0 0 2px 0.1rem yellowgreen !important;
}

/*ag grid radio button - used for AND/OR filter conditions*/
.ag-theme-alpine .ag-radio-button-input-wrapper.ag-checked:after {
    color: #449c45 !important;
}

/*ag-grid date*/
input[type=date]:focus {
    box-shadow: 0 0 2px 0.1rem #449c45 !important;
}

/*change calendar icon*/
/*FYi - https://stackoverflow.com/questions/62162645/change-color-of-chromes-calendar-icon-in-html-date-input*/
/*FYI - https://stackoverflow.com/a/43959856*/
input[type="date"]::-webkit-calendar-picker-indicator {
    color: #449c45 !important; /*rgb(68, 156, 69)*/
    filter: invert(50%) sepia(10%) saturate(2000%) hue-rotate(70deg);
}

/*remove the | from ag-grid table */
.ag-theme-alpine .ag-header-cell-resize:after{
  content: none!important;
}
.label-heading,.label-heading th{
  color: yellowgreen;
  text-transform: uppercase;
  font-family: 'Lato';
}
.style-text-content,
.style-text-content h1,.style-text-content h2,
.style-text-content h3,.style-text-content h4,
.style-text-content h5,.style-text-content table,
.style-text-content thead,.style-text-content tbody{
  color: black;
  font-family: 'Lato';
}
.btn-black,.btn-black:hover, .btn-black:focus, .btn-black:active {
  color: white !important;
  background-color: black !important;
  border: none !important;
  font-size: 12.5px !important;
}
.btn-green,.btn-green:hover{
  color: white !important;
  background-color: #8bc43f !important;
  font-size: 12.5px !important;
  border: none !important;
}
.btn-custom,.btn-success{
  background-color: #8bc43f;
}
.so-modal-font{
  font-size: 13px;
  font-family: 'Lato';
  color: black;
}
.m-5{
  margin: 5px;
}
.m-r-30{
  margin-right: 30px;
}
.m-u{
  margin: unset !important;
}
.p-u {
    padding: unset !important;
}
.p-t-5{
  padding-top: 5px;
}
.p-l-r-u{
  padding-left: unset;
  padding-right: unset;
}
.p-l-r-5 {
    padding-left: 5px;
    padding-right: 5px;
}
.b-r-1-s-g {
  border-right: 1px solid lightgrey;
}
.b-l-1-s-g {
  border-left: 1px solid lightgrey;
}
.b-t-1-s-g{
  border-top: 1px solid lightgrey;
}
.table > thead > tr > th, thead, th {
  text-align: center;
}
.restyle-form-control {
    background-color: white;
    border: 1px solid darkgrey;
}
.b-c-g{
  background-color: #efefef !important;
}
.bd-c{
  border: 1.5px solid #efefef;
}
.h-100{
  height: 100%;
}
.reduce-fc-width {
  width: 300px;
}
/* accordion styles */
.panel-group .panel .panel-heading a[data-toggle=collapse]:before {
    content: none;
}
.panel-group .panel .panel-heading .accordion-toggle:before {
    content: none;
}
.panel-default>.panel-heading {
  /* background-color: lightgrey; */
  border-bottom: 1px solid black !important;
}
.panel-group .panel+.panel {
  margin-top: unset;
}
/* overriding bootstrap */
.table>caption+thead>tr:first-child>td, .table>caption+thead>tr:first-child>th, .table>colgroup+thead>tr:first-child>td, .table>colgroup+thead>tr:first-child>th, .table>thead:first-child>tr:first-child>td, .table>thead:first-child>tr:first-child>th {
    border-top: 1px solid lightgrey;
}
.clearfix {
    overflow: auto;
}
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
.edit-table-btn{
  padding: unset;
  border: none;
  margin-right: 2px;
  color: #337ab7;
  background-color: transparent;
}

.embed-container {
    position: relative;
    padding-bottom: 30%;
    height: 150px;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    max-width: 100%;
}
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    overflow: hidden !important;
}

.embed-container embed body,
.embed-container object body {
  overflow: hidden !important;
  width: 270px !important;
}
.cards-div .col-lg-2,
.cards-div .col-sm-2{
  width: unset;
}
@media (max-width: 2560px) and (min-width: 1600px) {
  .so-to-cards{
    width: 350px;
  }
}

/* CSS for animated arrow */

.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  padding: 4px 0;
  width: 9px!important;
  margin-top: 6px;
  float: right;
}


.arw-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transition: transform 0.5s;
}

#sidebar-menu > ul > li > a:focus .arrow,#sidebar-menu > ul > li > a:hover .arrow{
  border-width: 0 1px 1px 0;
  margin-top: 6px;
}

.arw-right-t {
  transform: rotate(45deg)!important;
  -webkit-transform: rotate(45deg)!important;
  border-color:  #8BC53F;
}

.bdr-l-g {
  border-left: 3px solid #8bc43f !important;
  background: #F3F3F3 !important;
}
.bdr-l-g li::marker{
  color: #8bc43f !important;
}
.side-menu-div {
  width: 100%;
  border-left: 3px solid white;
}
.side-menu-div:hover {
  background: #F3F3F3 !important;
}
.side-menu-div > li, .sm-d > li {
  margin-left: 50px !important;
}
.li-n {
  list-style-type: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
.list-square div > li{
  list-style: square!important;
  margin-right: 10px;
  color: rgb(38, 34, 35)!important;
}

.list-square a{
  color: #262223!important;
}

.bg-extend{
  background-color: #f8f8f8;
  height: 100%;
}

/* CSS for header */

.header-flex{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid #f1f1f1;
}

.logo-left{
  width: 14%;
  /* margin: 0; */
  /* padding: 0; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  /*  margin-inline: auto; */
}

.menu-icon{
  padding: 0 0 0 15px;
}
.navigation-right{
  width: 86%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.admin-role{
  align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-inline: auto; */
    min-width: 78%;
}
.login-flex{
  align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-inline: auto; */
    min-width: 22%;
    list-style: none;
    margin-right: 10px;

}

.user-font{
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
}


.wd-85 {
  width: 85%;
  position: fixed;
  align-items: center;
  margin-left: 14%;
  padding-right: 20px;
}
@media all and (max-width: 1360px) and (min-width: 600px){
  .wd-85 {
    margin-left:172px;
    padding-left: 2%;
    width: 85%;
    padding-right: 7%;
  }
}

/*border less table - remove top and bottom border*/
/*https://stackoverflow.com/a/24830641*/
.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
    border-left: none;
    border-right: none;
}
/*For Borders in View Accounts Payables/Receivables Modal*/
.bdr-b{
  border-bottom: 1px solid #e5e5e5;
}
.bdr-t{
  border-top: 1px solid #e5e5e5;
}
.bdr{
  border: 1px solid #e5e5e5;
}
.bdr-b-tbl {
  border-bottom: 1px solid #d1d1d1;
}
.bdr-n{
  border: none !important;
}
.bg-t {
  background-color: transparent;
}
.m-l-a {
  margin-left: auto !important;
}
.m-r-a {
  margin-right: auto !important;
}
/* Styles for Platform Settings' Table*/
.tbl-cell > th {
  width:18%;
  font-weight: normal;
  text-align: center;
  color: #A0D061!important;
  background-color: #f8f8f8;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.ff-r span,td,th,p,label {
  font-family: sans-serif!important;
  font-weight: 500;
}
th,td {
  vertical-align: middle !important;

}
.i-csr {
  cursor: pointer;
}
.invisible-btn {
  background-color: #00000000 !important;
  border: none;
}
.i-n-a {
  cursor: not-allowed !important;
}
.i-n-a-p {
  cursor: not-allowed !important;
  pointer-events: none !important;
}
.ff-ss * {
  font-family: sans-serif;
  font-weight: 500;
}
.fs-21 h4{
  font-size: 21px;
}
.d-f-a-c {
  display: flex;
  align-items: center;
  justify-items: center;
}
.add-btn > span {
  display: flex;
  height: 29px;
  background-color: #8bc43f;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  border-radius: 4px;
}
.add-btn p {
  display: inline-block;
  color: white;
  margin: 2px 12px;
  font-size: 12.5px;
}

/* .add-btn-assign > span {
  display: flex;
  height: 29px;
  background-color: #8bc43f;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  border-radius: 4px;
} */

.add-btn-disabled > span{
  display: flex;
  height: 29px;
  background-color: #9ea397;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  border-radius: 4px;
}
.plus-icn {
  background-color: #449c45;
  width: 28px;
  height: 100%;
  margin: 0%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.plus-icn-disabled {
  background-color: #535d53;
  width: 28px;
  height: 100%;
  margin: 0%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.plus-icn ion-icon {
  font-size: 20px;
  color: white;
  margin: 4px 4px;
  margin-left: 2.5px;
}
.custom-input {
  width: 60%;
  color: rgb(255, 255, 255);
}
 .file-input {
  display: inline-block;
  color: rgb(255, 255, 255);
  width: 100%;
}
.cancel-btn > span {
  display: flex;
  height: 29px;
  background-color: #231F20;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  border-radius: 4px;
}
.cancel-btn p {
  display: inline-block;
  color: white;
  margin: 2px 12px;
  font-size: 12.5px;
}
.al-r {
  display: flex;
  justify-content: flex-end;
}
.r-align {
  display: flex;
  justify-content: flex-end;
}
.l-align {
  display: flex;
  justify-content: flex-start;
}
.c-align {
  display: flex;
  justify-content: center;
}
.ad-vendor-form > div {
  width: 49%;
}

.ad-vendor-form > div > div > label {
  width: 130px;
}
.ad-vendor-form .control-label {
  text-align: left !important;
  padding-left: 11px !important;
}
.address-details-form {
  background: #f9f9f9;
  width: calc(80% + 70px);
  padding: 15px;
}
/* #vendorType:required:invalid {
    color: #999999 !important;
} */
.csr-form > div {
  width: 40%;
}
.p-n {
  padding: none;
}
.doc-button {
  white-space: nowrap !important;
  height: 30px;
  background-color: white !important;
  color: black !important;
  border: 1px solid rgb(211, 211, 211) !important;
  border-radius: 2px;
}
.doc-button:hover {
  background-color: #8bc43f;
  color: white;
}
.ad-vendor-form .multiSelect{
  width: 390px;
  background-color: #8bc43f;
  height: 20px !important;
}

.ad-vendor-form .multiSelect .btn:hover,
.ad-vendor-form .btn-default:focus,
.ad-vendor-form .btn-default:active,
.ad-vendor-form .btn-default:hover,
.ad-vendor-form .open > .dropdown-toggle{
  background-color: #8bc43f !important;
  color: white !important;
  padding: 5px 10px !important;
  border: none !important;

}

/*to reduce the margin of top and bottom, when editable view is present*/
.category-input .editable-wrap .editable-controls,
.quantity-input .editable-wrap .editable-controls,
.category-input .editable-wrap .editable-error,
.quantity-input .editable-wrap .editable-error {
    margin-bottom: -20px;
    margin-top: -20px;
}

/* view-accounts-payable-modal.html style classes */
.acc-payable-input{
  height: 31px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 75%;
}

.acc-payable-title{
  font-size: medium;
  font-weight: normal;
  color: yellowgreen!important;
}

::placeholder{
  color: #999797;
  padding-left : 10px;
}

.acc-payable-table  th{
  color: #A0D061!important;
  font-family: sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  padding-inline: 10px;
  border-bottom: 0px!important;
}

.acc-payable-table  td{
  background-color: white;
  padding-inline: 10px;
  color: #484546;
}

.modal-body-color{
  color:#484546;
}

.acc-payable-submit{
  text-align: right;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom:15px;
    margin-inline:15px;
}

.submit-button{
  background-color: #8BC53F!important;
  border: 1px solid #8BC53F!important;
  color: white;
}

.altClr tr:nth-child(odd)
{background-color: #f4f4f4;}

.width-10 {
  width : 8%!important;
}

.ag-rich-select {
  height: 135px !important;
}

/* // input styles as per designs */

.input-div-design{
  padding-left: 3%;
  width: 80%;
}
.input-style-design{
  border: 1px solid #E7E7E7;
  border-bottom: 2px solid #E7E7E7;
  border-radius: 4px;
  padding: 4px;
  background-color: white;
  width: 100%;
}
.input-style-design-error{
  border: 1px solid red;
  border-bottom: 2px solid red;
  border-radius: 4px;
  padding: 4px;
}

.row-style-design{
  width: 95%;
  margin-inline: auto;
  margin-bottom: 15px;
  text-align: left;
  color: #797979;
  /* padding-right: 5%; */
}

.col-label-design{
  padding-top:5px;
  /* font-size: 13px; */
  padding-right: 20px;
}

.btn-close-design{
      font-size: 26px;
    font-weight: 400;
}
.btn-design-success{
  background-color: #8BC53F !important;
    border: 1px solid #8BC53F !important;
    color: white;
}
.heading-style{
  color: yellowgreen;
    font-size: 22px;
    font-family: 'Lato';
}
.columns{
  columns: 5;
}
.sales-btn-design{
  margin: 5px;
  border: 2px solid #8BC53F;
  border-radius: 7px;
  color: #8BC53F;
  font-weight: 500;
  font-style: inherit;
  background: white;
  padding: 3px;
}

.sales-btn-design:hover{
  background: #8BC53F;
    color: white;
    /* padding: 5px; */
    transform: scale(1.1);
    transition: transform 0.2s;
}
.sales-btn-design:focus{
  background: #8BC53F;
    color: white;
    /* padding: 5px; */
    transform: scale(1.1);
}



.sales-btn-design label{
  margin: 5px;
  border: 2px solid #8BC53F;
  border-radius: 5px;
  color: #8BC53F;
  font-weight: 500;
  font-style: inherit;
  background: white;
}

.sales-input-design{
  margin: 5px;
  border: 2px solid #8BC53F;
  border-radius: 7px;
  color: #8BC53F;
  font-weight: 500;
  font-style: inherit;
  background: white;
  padding: 3px;
}


.donate-now {
  list-style-type: none;
  /* margin: 25px 0 0 0; */
  padding: 0;
}

.donate-now li {
  float: left;
  margin: 5px 5px 0 0;
  width: 275px;
  height: 40px;
  position: relative;
}

.donate-now label,
.donate-now input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.donate-now input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}

.donate-now input[type="radio"]:checked+label,
.Checked+label {
  background: #8BC53F;
  color: white;
}

.selected-salesPerson{
  background: #8BC53F!important;
  color: white!important;
  /* color:red; */
}

.donate-now label {
  padding: 7px;
  border: 1px solid #8BC53F;
  cursor: pointer;
  z-index: 90;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
}

.donate-now label:hover {
  background: #DDD;
}

.scroll-design{
  overflow-y: scroll;
  height: 400px;
  /* width: 53%; */
}

.btn-green-submit{
  color: white !important;
  background-color: #8bc43f !important;
  font-size: 13px !important;
    border: none !important;
    padding: 8px 10px;
    border-radius: 3px;
}
.flex-column {
  width: 48%;
}
.add-sales-agent-form label {
  width: 130px;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.address-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  border: none !important;
}
.address-dropdown button {
  text-align: left;
}
.address-dropdown-content {
  display: none;
  background-color: #fff;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  height: 240px;
  border: 1px solid black;
  cursor: alias;
}
.address-dropdown-content a {
  color: black;
  padding:5px 10px;
  text-decoration: none;
  text-align: left;
  display: block;
  border: 1px solid rgb(246, 246, 246);
}
.address-dropdown a:hover {
  color: white;
  background-color: #1a73e8;
  cursor: alias;
}

.align-center {
  margin: 0 auto;
}

.documents-containor {
  flex-wrap: wrap;
  border-radius: 5px;
  padding: 5px;
  gap: 10px;
}

.consumer-document {
  width: 218px;
  padding: 0px 15px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.consumer-document > label {
  width: 100%;
  height: 20px;
  overflow: hidden;
  margin-bottom: 0;
}

.sub-form-title > p {
  margin-left: 80px !important;
  color: #8BC53F !important;
  font-size: 15px;
  font-weight: 500 !important;
}

.clr-g a {
  color: #8bc43f !important;
}

.group-appointments-containor > div {
  width: 32%;
  flex-wrap: wrap;
  padding-left: 5px;
  border-right: 1px solid gainsboro;
}
.inner-section > div {
  width: 95%;
}
.apt-details-sell > div {
  width: 95%;
}
.sq-clr {
  background: #8bc43f;
}

.apt-details {
  color: #000;
  font-family: "Lato" !important;
}

.db {
  display: block;
}