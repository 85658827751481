.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.ng-table-counts{
  display:none;
}
.ng-table-pager{
  text-align:right;
}

.cropArea {
      background: #E4E4E4;
      overflow: hidden;
      width:500px;
      height:350px;
}

/* .numberedit{
  background: none;
  border: 2px solid black;
} */

html .customPrice:disabled{
  background: transparent;
  border: none;
}

input.customPrice:enabled{
  padding: 5px !important;
  border: 1px solid #5fbeaa !important;
}